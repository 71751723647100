import React from 'react'
import '../../App.css'


function SideIcon({ floatingLeftIcon, floatingRightIcon }) {
    return (
        <div className="sideicon md:block hidden">
            <div className="back-shapes">
                <span className="floating meditationGirl" style={{ backgroundImage: `url('assets/svg/${floatingLeftIcon}')`, left: '3.020833333333334%', animationDelay: '-0.9s' }}></span>
                <span className="floating meditationBoy" style={{ backgroundImage: `url('assets/svg/${floatingRightIcon}')`, right: '1.59375%', animationDelay: '-4.8s' }}></span>
            </div>
        </div>
    )
}

export default SideIcon