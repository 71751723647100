import React from 'react'
import Heading from '../Heading/Heading'
import Button from '../Heading/Button'
import SideIcon from '../SideIcon/SideIcon'

let colors = ['#fa9db7', 'rgb(253,197,99)', 'rgb(69,179,223)', '#95adfa', '#b6dd6b', '#d8a7e2']

const upgradation = [
    {
        childImg: 'assets/ChildUpgradation/brainactivation.png',
        course: 'Brain Activation',
        description: 'Brain activation refers to the process of different regions of the brain becoming active or "lighting up" when we perform certain tasks or experience certain emotions. It’s like different parts of the brain are working together to help us think, feel, and react. It’s pretty fascinating! 🧠✨'
    },
    {
        childImg: 'assets/ChildUpgradation/boostedskills.png',
        course: 'Boosted Memory Skills',
        description: 'Boosted memory skills refer to an improved ability to remember and retain information. It’s like giving your memory a little extra power to recall things more effectively. 🧠🚀'
    },
    {
        childImg: 'assets/ChildUpgradation/mathematicalActivity.png',
        course: 'Mathematical Activity',
        description: 'Hey there! How about a fun math activity for kids? Try this: "Math Magic: Solve the Puzzle! Can you find the missing number?" 🧮✨ It’s a great way to engage their problem-solving skills while having fun! Enjoy!'
    },
    {
        childImg: 'assets/ChildUpgradation/ambidextrisActivity.png',
        course: 'Ambidextrous Activity',
        description: 'Hey there! How about a fun activity for kids to practice both-hand writing? It’s called "Dual Penmanship Delight!" 🖊️✨ Encourage them to write a sentence or a short story using their dominant hand for the first half and then switch to their non-dominant hand for the second half. It’s a fun challenge that helps improve coordination and creativity. Happy writing!'
    },
    {
        childImg: 'assets/ChildUpgradation/ec&image.png',
        course: 'Enhanced Creativity And Imagination',
        description: 'Enhanced creativity and imagination can lead to a greater ability to think outside the box, come up with unique ideas, and envision new possibilities. It’s like unlocking the full potential of your creative mind and letting your imagination soar. 🌟🎨'
    },
    {
        childImg: 'assets/ChildUpgradation/stressredution.png',
        course: 'Stress Reduction',
        description: 'Stress reduction refers to techniques and practices that help to alleviate or manage stress levels. It’s like finding ways to relax, unwind, and take care of your mental and emotional well-being. Some common methods include exercise, deep breathing, meditation, and engaging in activities you enjoy. 🌿😌'
    }
]

function Childupgradation() {
    return (
        <div className=''>
            <div className='px-5 md:mt-28 mt-12'>
                <SideIcon floatingLeftIcon="calm.png" floatingRightIcon="yoga.png" />
                <div className='text-center mb-10'>
                    <Heading HeadingOne="Our Focus" HeadingTwo="Child Upgradation" />
                </div>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-4">
                    {
                        upgradation.map((i, index) => {
                            return (
                                <>
                                    <div className="flex cursor-pointer h-full dark:bg-gray-800 bg-[colors] flex-col transform translate-y-4 hover:translate-y-0 duration-500 ease-in-out rounded-2xl shadow-1 p-5 pb-7" style={{ backgroundColor: colors[index] }}>
                                        <div className='flex items-center'>
                                            <img src={i.childImg} className="inline-block rounded-2xl md:h-32 h-28" />
                                            <h2 className="pl-2 dark:text-white md:text-3xl text-xl font-bold text-white">{i.course}</h2>
                                        </div>
                                        <div className='p-4 pb-0'>
                                            <p className="md:text-base text-sm leading-relaxed text-white font-medium">{i.description}</p>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Childupgradation