import React, { useEffect, useState } from 'react';
import { useFirebase } from './Firebase';
import { toast } from 'react-toastify';
import Heading from './Heading';

toast.configure();

function AdminSelectedImage(props) {
    const firebase = useFirebase();
    const [galleryData, setGalleryData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [displayCount, setDisplayCount] = useState(20); // Initially show 20 images
    const [isShowingMore, setIsShowingMore] = useState(false); // To toggle between view more and view less

    useEffect(() => {
        loadData();
    }, [props.dataToggle]);

    const loadData = async () => {
        setIsLoading(true);
        try {
            const data = await firebase.fileData("Image", "All Photos");
            setGalleryData(data.docs);
        } catch (error) {
            console.error("Error loading data:", error);
            toast.error("Error loading data.");
        } finally {
            setIsLoading(false);
        }
    };

    const deleteButton = async (path, id) => {
        console.log("started");
        firebase.deleteData(path, id);
        console.log("ended");
        toast.success("Data deleted", { autoClose: 1000 });
        loadData();
    };

    const handleViewMore = () => {
        setDisplayCount(galleryData.length); // Show all images
        setIsShowingMore(true); // Update state to reflect "View Less"
    };

    const handleViewLess = () => {
        setDisplayCount(20); // Show only 20 images
        setIsShowingMore(false); // Update state to reflect "View More"
    };

    return (
        <div className='mt-8 container'>
            <div className='text-center'>
                <h4 className='text-[#12265a] lg:text-3xl md:text-2xl break-words text-2xl font-extrabold'>Images</h4>
            </div>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <div className='grid lg:grid-cols-6 md:grid-cols-5 grid-cols-3 gap-5 pt-12'>
                        {
                            galleryData.slice(0, displayCount).map((imagesItem) => {
                                return (
                                    <div key={imagesItem.data().id}>
                                        <div className='overflow-hidden flex rounded-3xl cursor-pointer ltr:pr-0 rtl:pl-0 flex-col items-start transition duration-200 ease-in-out transform hover:-translate-y-1 md:hover:-translate-y-1.5 hover:shadow-sm bg-white'>
                                            <img
                                                src={imagesItem.data().fileUrl}
                                                style={{ width: 'auto', height: 'auto' }}
                                                className="block max-w-full bg-none opacity-100 border-0 m-0 p-0"
                                                alt="Preview"
                                            />
                                        </div>
                                        <div className='flex justify-between mt-2'>
                                            <div className="text-[#12265a] text-base font-normal">{imagesItem.data().categoryId}</div>
                                            <button
                                                onClick={() => deleteButton(imagesItem.data().fileUrl, imagesItem.data().id)}
                                                className="size-4 mt-1 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center bg-red-600 rounded-full">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="10" width="7.5" viewBox="0 0 384 512">
                                                    <path fill="#fefbfb" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='text-center mt-4'>
                        {!isShowingMore ? (
                            <button
                                onClick={handleViewMore}
                                className="px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-300">
                                View More
                            </button>
                        ) : (
                            <button
                                onClick={handleViewLess}
                                className="px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-300">
                                View Less
                            </button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}

export default AdminSelectedImage;
