import React from 'react'
import PageTitle from '../Header/PageTitle'
import Heading from '../Heading/Heading'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const blogItem = [
  {
    id: 1,
    blogListName: 'Nutrition',
    blogItemList: [
      {
        blogItemName: 'Balanced Diet',
        blogItemDescription: 'Include fruits, vegetables, whole grains, and lean proteins.'
      },
      {
        blogItemName: 'Probiotics',
        blogItemDescription: 'Foods like yogurt support gut health.'
      },
      {
        blogItemName: 'Hydration',
        blogItemDescription: 'Encourage drinking plenty of water.'
      },

    ]
  },
  {
    id: 2,
    blogListName: 'Lifestyle Changes',
    blogItemList: [
      {
        blogItemName: 'Exercise',
        blogItemDescription: 'Regular physical activity improves circulation.'
      },
      {
        blogItemName: 'Sleep',
        blogItemDescription: 'Ensure adequate sleep for their age.'
      },
      {
        blogItemName: 'Stress Management',
        blogItemDescription: 'Teach stress-relief techniques.'
      },

    ]
  },
  {
    id: 3,
    blogListName: 'Natural Remedies',
    blogItemList: [
      {
        blogItemName: 'Herbal Supplements',
        blogItemDescription: 'Consult a healthcare provider for safe options.'
      },
      {
        blogItemName: 'Honey and Turmeric',
        blogItemDescription: 'Incorporate into meals.'
      },
      {
        blogItemName: 'Essential Oils',
        blogItemDescription: 'Use in diffusers.'
      },

    ]
  },
  {
    id: 4,
    blogListName: 'Hygiene Practices',
    blogItemList: [
      {
        blogItemName: 'Hand Washing',
        blogItemDescription: 'Regularly wash hands with soap.'
      },
      {
        blogItemName: 'Avoid Sick Contacts',
        blogItemDescription: 'Practice good hygiene to prevent infections.'
      },
    ]
  },
  {
    id: 5,
    blogListName: 'Outdoor Activities',
    blogItemList: [
      {
        blogItemName: 'Sunlight Exposure',
        blogItemDescription: 'Vital for Vitamin D production.'
      },
      {
        blogItemName: 'Nature Walks',
        blogItemDescription: 'Reduce stress and increase physical activity.'
      },
    ]
  },
]
const blogTags = ['Meditation', 'Brain Gym', 'Focus', 'Concentration', 'Kids Activities', 'Brain Activities', 'Brain Activation']

function Blog() {
  return (
    <div>
      <PageTitle title="Blog" activeTitle="Blog" />
      <div className='container relative lg:mt-20 md:mt-10 mt-5'>
        <div className='grid lg:grid-cols-12 md:grid-cols-2 gap-5'>
          <div className='lg:col-span-9'>
            <div>
              <h4 className='text-[#12265a] lg:text-4xl md:text-3xl text-2xl mb-8 break-words font-extrabold'>Boost Your Child's Immunity Naturally</h4>
              <div className='mb-10'>
                <div className='relative rounded-lg overflow-hidden h-96 mb-8'>
                  <img className='rounded-lg h-full w-full' src="assets/Blog/blog.jpeg" />
                </div>
                <p className='text-[#616262] text-base leading-loose font-medium'>
                  Strengthening your child's immune system is essential for their health and well-being. Here are effective ways to naturally boost immunity through nutrition, lifestyle changes, and natural remedies.
                </p>
              </div>
              <div className='flex flex-col gap-5 mb-10'>
                {
                  blogItem.map((blogItems, index) => {
                    return (
                      <>
                        <div className='' key={index}>
                          <h4 className='text-[#12265a] lg:text-3xl md:text-2xl text-2xl mb-6 break-words font-extrabold'>{blogItems.blogListName}</h4>
                          <div className='border-2 border-dashed border-[#fa9db7] rounded-xl p-5' >
                            {
                              blogItems.blogItemList.map((blogItemLists, index) => {
                                return (
                                  <>
                                    <div className='flex flex-wrap lg:gap-5 md:gap-5 gap-2 mb-3'>
                                      <div className=''>
                                        <div className="size-10 bg-[#45b3df] text-[#ffffff] rounded-full text-2xl font-bold flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                          <svg
                                            baseProfile="tiny"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            height="1em"
                                            width="1em"
                                          >
                                            <path d="M16.972 6.251a1.999 1.999 0 00-2.72.777l-3.713 6.682-2.125-2.125a2 2 0 10-2.828 2.828l4 4c.378.379.888.587 1.414.587l.277-.02a2 2 0 001.471-1.009l5-9a2 2 0 00-.776-2.72z" />
                                          </svg>
                                        </div>
                                      </div>
                                      <div>
                                        <h4 className='self-center justify-center text-[#12265a] lg:text-2xl md:text-xl text-xl font-bold'>{blogItemLists.blogItemName} :</h4>
                                      </div>
                                      <div>
                                        <p className='self-center justify-center text-[#616262] text-base leading-loose font-medium'>{blogItemLists.blogItemDescription}</p>
                                      </div>
                                    </div>
                                    {/* <div className='flex gap-5 mb-3'>
                                <div className="relative flex gap-3">
                                  <div className="size-10 bg-[#45b3df] text-[#ffffff] rounded-full text-2xl font-bold flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <svg
                                      baseProfile="tiny"
                                      viewBox="0 0 24 24"
                                      fill="currentColor"
                                      height="1em"
                                      width="1em"
                                    >
                                      <path d="M16.972 6.251a1.999 1.999 0 00-2.72.777l-3.713 6.682-2.125-2.125a2 2 0 10-2.828 2.828l4 4c.378.379.888.587 1.414.587l.277-.02a2 2 0 001.471-1.009l5-9a2 2 0 00-.776-2.72z" />
                                    </svg>
                                  </div>
                                  <h4 className=' self-center justify-center text-[#12265a] lg:text-2xl md:text-xl text-xl font-bold'>{blogItemLists.blogItemName} :</h4>
                                  <p className='self-center justify-center pt-1 text-[#616262] text-base leading-loose font-medium'>{blogItemLists.blogItemDescription}</p>
                                </div>
                              </div> */}
                                  </>
                                )
                              })
                            }
                          </div>
                        </div>
                      </>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className='lg:col-span-3 mt-10'>
            <div className='grid grid-col gap-10'>
              <div>
                <div className='text-center text-[#12265a] text-xl font-black mb-3'>GALLERY
                  {/* <div className='text-transparent bg-clip-text bg-gradient-to-r to-pink-400 from-red-200 underline underline-offset-3 decoration-8 decoration-pink-200 dark:decoration-pink-200'>TAGS</div> */}
                </div>
                <div className='grid grid-cols-3 gap-2'>
                  <div className="mt-3">
                    <img className="border rounded-2xl" src="assets/Gallery/photo1.png" alt="" />
                  </div>
                  <div className="mt-3">
                    <img className="border rounded-2xl" src="assets/Gallery/meditation9.png" alt="" />
                  </div>
                  <div className="mt-3">
                    <img className="border rounded-2xl" src="assets/Gallery/Reward8.png" alt="" />
                  </div>
                  <div className="mt-3">
                    <img className="border rounded-2xl" src="assets/Gallery/blindFold2.png" alt="" />
                  </div>
                  <div className="mt-3">
                    <img className="border rounded-2xl" src="assets/Gallery/photo7.png" alt="" />
                  </div>
                  <div className="mt-3">
                    <img className="border rounded-2xl" src="assets/Gallery/meditation4.png" alt="" />
                  </div>
                </div>
              </div>
              {/* Tags */}
              <div className=''>
                <div className='text-center text-[#12265a] text-xl font-black mb-6'>TAGS
                  {/* <div className='text-transparent bg-clip-text bg-gradient-to-r to-pink-400 from-red-200 underline underline-offset-3 decoration-8 decoration-pink-200 dark:decoration-pink-200'>TAGS</div> */}
                </div>
                <div className='flex flex-wrap gap-3 cursor-pointer'>
                  {
                    blogTags.map((tag) => {
                      return (
                        <div className='inline-block bg-[#fcf9f4] text-[#70747f] font-normal text-sm rounded-full px-4 py-2 hover:text-white hover:bg-[#ffc85b] transition-all duration-500'>{tag}</div>

                      )
                    })
                  }
                </div>
              </div>

              {/* Contact Form */}
              <div className="relative p-4 w-full rounded-lg text-center bg-center bg-cover  bg-no-repeat" style={{ backgroundImage: `url('assets/Blog/bgBanner.png')` }}>
                <div className='pt-9 pb-11'>
                  <h2 className='text-white text-2xl font-bold mb-5 leading-relaxed'>Enroll Your Child Now! Call Us</h2>
                  <h2 className='text-white text-base font-bold mb-5 leading-relaxed'>+91-9013790037 or <br />+1(510) 861-8713</h2>
                  <Link to="/contact" className='relative inline-block w-full text-center rounded-full bg-white hover:bg-[#ffc85b] text-[#45b3df] hover:text-white p-2 transition duration-500'>
                    <div className='border-2 border-dashed border-[#45b3df] rounded-full p-3 text-base font-bold hover:border-white'>
                      Enroll Online
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Conclusion */}
        <div className='mt-10'>
          <div className="relative text-transparent">
            <div className="mb-5 size-16 bg-[#fa9db7] text-[#ffffff] rounded-lg text-3xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
              <svg
                viewBox="0 0 16 16"
                fill="currentColor"
                height="1em"
                width="1em"
              >
                <path
                  fill="currentColor"
                  d="M3.516 7a3.5 3.5 0 11-3.5 3.5L0 10a7 7 0 017-7v2a4.97 4.97 0 00-3.536 1.464 5.01 5.01 0 00-.497.578c.179-.028.362-.043.548-.043zm9 0a3.5 3.5 0 11-3.5 3.5L9 10a7 7 0 017-7v2a4.97 4.97 0 00-3.536 1.464 5.01 5.01 0 00-.497.578c.179-.028.362-.043.549-.043z"
                />
              </svg>
            </div>
            <h4 className='text-[#12265a] lg:text-2xl md:text-xl text-xl mb-6 font-bold text-center'>Implement these strategies to help your child build a strong immune system and enjoy better health.</h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blog