import React from 'react'
import Heading from '../Heading/Heading'
import Button from '../Heading/Button'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const ourGallery = [
    { image: 'assets/Gallery/photo7.png' },
    { image: 'assets/Gallery/meditation9.png' },
    { image: 'assets/Gallery/blindFold3.png' },
    { image: 'assets/Gallery/news1.png' },
    { image: 'assets/Gallery/Reward5.png' },
    { image: 'assets/Gallery/interview5.png' },
]



function OurGallery() {
    return (
        <div className='md:mt-28 mt-12 px-5'>
            <div className='grid grid-cols-12 mb-10 justify-between'>
                <div className='col-span-8 md:col-span-10'>
                <Heading HeadingOne="Our" HeadingTwo="Gallery" />
                </div>
                <div className='col-span-4 md:col-span-2 content-center text-center'>
                <Link to="/gallery"> <Button buttonText="View More" /> </Link>
                </div>
            </div>
            <div className='grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-5 lg:gap-10'>
                {
                    ourGallery.map((images) => {
                        return (
                            <div className='group'>
                                <span className="box-border rounded-3xl inline-block w-full opacity-100 border-0 m-0 relative max-w-full transform translate-y-4 hover:translate-y-0 duration-500 ease-in-out hover:zoom-in">
                                    <span className="box-border inline-block bg-none opacity-100 border-0 m-0 p-0 relative max-w-full">
                                        <img src={images.image} className="min-w-full rounded-3xl max-w-full min-h-full max-h-full w-full block object-cover bg-none opacity-100 border-0 m-0 p-0" />
                                    </span>
                                </span>

                            </div>


                        )
                    })
                }
            </div>
        </div>
    )
}

export default OurGallery