import React, { useEffect, useState } from 'react';

const ModalForm = ({ closeModal, parentsModalName }) => {
    const [isVisible, setIsVisible] = useState(true);

    // Automatically close the modal after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            closeModal(); // Call the function to close the modal
        }, 10000); // 10000 milliseconds = 10 seconds

        // Clear the timer if the component unmounts or isVisible becomes false
        return () => clearTimeout(timer);
    }, [closeModal]); // Dependency on closeModal ensures useEffect runs when closeModal changes
    return (
        <>
            {isVisible && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                    <div className="fixed inset-0 bg-black opacity-50"></div> {/* Dark transparent background */}
                    <div className="relative w-auto max-w-3xl mx-auto my-6">
                        {/* Modal content */}
                        <div className="bg-white rounded-lg shadow-lg relative flex flex-col p-8">
                            <div className="flex justify-end">
                                <button
                                    className="text-gray-400 hover:text-gray-800 focus:outline-none"
                                    onClick={closeModal}
                                >
                                    <svg className="h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <div className="mt-6">
                                <h2 className="text-2xl font-bold text-gray-800">Dear {parentsModalName}, </h2>
                                <p className="text-gray-600 mt-3"> Thank you for reaching out to us. We appreciate your interest in Bacchapanti and are delighted to hear you.
                                    Looking forward to connecting with you soon. </p>
                                <p className='mt-3 text-center'><b>Bacchapanti Team</b><br />
                                    Eager to assist you</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ModalForm;
