import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useFirebase } from '../Helper/Firebase';
import { useHistory } from 'react-router-dom';
import PageTitle from '../Header/PageTitle';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const history = useHistory();
    const firebase = useFirebase();

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Reset errors
        setEmailError('');
        setPasswordError('');

        // Validate input
        if (!email) {
            setEmailError('Email is required');
            return;
        }

        if (!validateEmail(email)) {
            setEmailError('Invalid email address');
            return;
        }

        if (!password) {
            setPasswordError('Password is required');
            return;
        }

        try {
            const result = await firebase.login(email, password);
            console.log('User LogIn Successfully!!');
            toast.success('User LogIn Successfully!!', {
                position: 'top-center',
            });
            localStorage.setItem('authToken', 'your-token-here');
            history.push('/admin');
        } catch (error) {
            console.log(error.message);
            toast.error(error.message, {
                position: 'bottom-center',
            });
        }
    };

    return (
        <>
            <PageTitle title="Admin" activeTitle="Admin Login" />
            <div className='container relative lg:mt-20 md:mt-10 mt-5'>
                <div className=' flex flex-wrap justify-center'>
                    <div className='bg-[#ffb9cc] rounded-3xl p-8 w-full max-w-xl'>
                        <form className="flex emailForm flex-col gap-8" onSubmit={handleSubmit}>
                            <div>
                                <input
                                    className={`rounded-3xl border ${emailError ? 'border-red-500' : 'border-gray-200'} w-full py-5 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                                    placeholder="Your Email...."
                                    name='email'
                                    type='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {emailError && <p className="text-red-800 text-sm pt-2">{emailError}</p>}
                            </div>
                            <div>
                                <input
                                    className={`rounded-3xl border ${passwordError ? 'border-red-500' : 'border-gray-200'} w-full py-5 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                                    type="password"
                                    placeholder="Password:"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                {passwordError && <p className="text-red-800 text-sm pt-2">{passwordError}</p>}
                            </div>
                            <button
                                type="submit"
                                className="relative inline-block w-full text-center rounded-full bg-[#45b3df] text-white p-2 transition duration-500"
                            >
                                <div className='border-2 border-dashed border-white rounded-full p-3 text-base font-bold'>
                                    Log In
                                </div>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
