import React from 'react'

let colors = ['#fa9db7', 'rgb(253,197,99)', 'rgb(69,179,223)', '#95adfa', '#b6dd6b', '#d8a7e2']

const upgradation = [
    {
        description: 'Make kid’s development courses accessible globally through online platforms and offline centers.'
    },
    {
        description: 'Enhance cognitive skills and academic performance through engaging programs'
    },
    {
        description: 'Foster creativity, problem-solving, and innovation in children.'
    },
    {
        description: 'Cultivate holistic development, including cognitive, emotional, social, and physical aspects.'
    },
    {
        description: 'Promote cultural heritage by integrating Vedic teachings into the curriculum.'
    },
    {
        description: 'Build a global community of parents, educators, and children passionate about kid’s development and Vedic sciences.'
    }
]

function Objectives() {
    return (
        <div className='container relative px-5 pt-10'>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-4">
                {
                    upgradation.map((i, index) => {
                        return (
                            <>
                                <div className="flex h-full dark:bg-gray-800 bg-[colors] flex-col transform translate-y-4 hover:translate-y-0 duration-500 ease-in-out rounded-2xl shadow-2 shadow-lg p-5 pb-7" style={{ backgroundColor: colors[index] }}>
                                    <div className='p-4 pb-0'>
                                        <p className="text-base leading-relaxed text-white font-medium ">{i.description}</p>
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Objectives