import React from 'react'

function Heading({ HeadingOne, HeadingTwo }) {
  return (
    <div>
      <h1 className="mb-2 text-2xl font-extrabold text-gray-900 dark:text-white md:text-4xl lg:text-5xl">
        <span className="text-transparent bg-clip-text bg-gradient-to-r to-cyan-400 from-sky-200">{HeadingOne}</span>
      </h1>
      <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-4xl lg:text-6xl">
        <span className="text-transparent bg-clip-text bg-gradient-to-r to-pink-400 from-red-200 underline underline-offset-3 decoration-8 decoration-pink-200 dark:decoration-pink-200">{HeadingTwo}</span>
      </h1>
    </div>
  )
}

export default Heading