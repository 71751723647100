import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

function PageTitle({ title, activeTitle }) {
    return (
        <div style={{ backgroundImage: `url("assets/Img/page_title_bg.png")`, backgroundPosition: 'center bottom' }} className='flex flex-col h-[320px] bg-no-repeat bg-cover bg-scroll '>
            <div className='text-center pt-20'>
                    <h3 className='text-[#12265a] text-[segoepr] leading-relaxed text-4xl font-bold'>{title}</h3>
                    <div className="flex justify-center gap-2 cursor-pointer">
                        <Link to="/"><h2 className='text-[#12265a] text-[segoepr] text-xl font-bold'>Home / </h2></Link>
                        <h2 className='text-2xl font-bold text-[#fa9db7]'> {activeTitle} </h2>
                    </div>
                </div>
        </div>
    )
}

export default PageTitle