import { TailSpin } from "react-loader-spinner";
const Loader = (loading) => {
    return (
        <TailSpin
            height="80"
            width="80"
            textAlign="center"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loading}
        />
    );
};  
export default Loader;