
import { initializeApp } from "firebase/app";
import { collection, addDoc, getFirestore, where, query, getDocs, doc, setDoc, deleteDoc } from "firebase/firestore";
import { createContext, useContext, useState } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject} from "firebase/storage"
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";


const FirebaseContext = createContext(null)

// const firebaseConfig = {
//   apiKey: "AIzaSyBeksrzs9nNuNreVxvZ-EOZvCru_gY9FVA",
//   authDomain: "bacchapanti-main.firebaseapp.com",
//   projectId: "bacchapanti-main",
//   storageBucket: "bacchapanti-main.appspot.com",
//   messagingSenderId: "606683592938",
//   appId: "1:606683592938:web:05453ec5f22062896e3f93"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBSw6cqtok0XrFaTNQf_X3I00lEORJ5aoY",
  authDomain: "bacchapanti-main-b3b25.firebaseapp.com",
  projectId: "bacchapanti-main-b3b25",
  storageBucket: "bacchapanti-main-b3b25.appspot.com",
  messagingSenderId: "222528457362",
  appId: "1:222528457362:web:380bc8af085f56b973f44b"
};

export const useFirebase = () => useContext(FirebaseContext)

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth();

export const FirebaseProvider = (props) => {

  async function uploadImage(image) {
    const storageRef = ref(storage, `/uploads/${image.name}`);

    const response = await uploadBytes(storageRef, image);
    const url = await getDownloadURL(response.ref);
    return url;
  }

  const uploadMultipleImages = async (images) => {
    const imagePromises = Array.from(images, (image) => uploadImage(image));

    const imageRes = await Promise.all(imagePromises);
    return imageRes; // list of url like ["https://..", ...]
  }

  const deleteImage = async (path) => {
    const desertRef = ref(storage, path);
    deleteObject(desertRef).then(() => {
      // File deleted successfully
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
  }

  const addProduct = async (data) => {
    console.log(data)
    if(data.type === "Image"){
    for(let i = 0;i<data.fileUrl.length;i++)
    {   
      const newDocRef = doc(collection(db, "File"))
      await setDoc(
        newDocRef, 
        {
          id: newDocRef.id,
          type: data.type,
          categoryId: data.categoryId,
          fileUrl: data.fileUrl[i],
          thumbnail : data.thumbnail
        })
    }
  }
  else{
    const newDocRef = doc(collection(db, "File"))
      await setDoc(
        newDocRef, 
        {
          id: newDocRef.id,
          type: data.type,
          categoryId: data.categoryId,
          fileUrl: getVideoId(data.fileUrl),
          thumbnail : data.thumbnail
        })
  }
    //return await addDoc(collection(db, "Images"), data);

  }

  function getVideoId(url) {
    return url.replace('watch?v=', 'embed/')
  }

  const fileData = async(type,categoryId) =>{
    var q;
    if(categoryId === "All Photos" || categoryId === "All Videos"){
      q = query(collection(db, "File"), where("type", "==", type));
    }
    else {
      q = query(collection(db, "File"), where("type", "==", type), where("categoryId", "==", categoryId));
    }
    const querySnapshot = await getDocs(q);
    // querySnapshot.forEach((doc) => {
    // const result  = doc.data() 
    // //is never undefined for query doc snapshots
    // //console.log(doc.data());
    // return result
    // });
    return querySnapshot
  }

  const deleteData = async (path,id) => {
    const fileRef = ref(storage, path);
      // Delete the file using the delete() method 
        deleteObject(fileRef).then(function () { 
      // File deleted successfully 
      console.log("File Deleted") 
      }).catch(function (error) { 
      // Some Error occurred 
      });    
      deleteCollection(id) 
  }


  const deleteCollection = async (id) => {
    deleteDoc(doc(db, "File", id));
  }

  const login = async (email, password) => {

    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        console.log(user);
        //return user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }

  const logOut = async () => {
    signOut(auth).then(() => {
      localStorage.removeItem("authToken")
    }).catch((error) => {
      // An error happened.
    });
  }

  return (
    <FirebaseContext.Provider value={{      
      uploadMultipleImages,
      deleteImage,
      addProduct,
      fileData,
      deleteData,
      login,
      logOut
    }}>
      {props.children}
    </FirebaseContext.Provider>
  );
}




