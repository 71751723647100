import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

const isAuthenticated = () => {
  // Add your authentication logic here (e.g., check if token exists)
  return !!localStorage.getItem('authToken');
};

export default PrivateRoute;
