import React, { useState } from 'react'
import PageTitle from '../Header/PageTitle'
import GalleryPhotos from './GalleryPhotos'
import GalleryVideos from './GalleryVideos'


const GalleryItems = ['Images', 'Videos']

function Gallery() {
  const [togglePhotos, setTogglePhotos] = useState(0);

  const toggleTab = (index) => {
    setTogglePhotos(index);
  };

  return (
    <div className=''>
      <PageTitle title="Gallery" activeTitle={togglePhotos === 0 ? 'Images' : 'Videos'} />
      <div className='container relative'>
        <div className='flex flex-wrap justify-center flex-grow gap-5'>
          {GalleryItems.map((items, index) => (<>
            <div key={index}>
              <button
                className={togglePhotos === index ? "relative block bg-none text-[#45b3df] text-3xl font-extrabold after:absolute after:left-0 after:bottom-[-12px] after:bg-[#45b3df] after:h-1 after:w-full" : "relative block font-bold bg-none text-[#fa9db7] text-3xl"}
                onClick={() => toggleTab(index)}
              > {items}
              </button>
            </div>
          </>
          ))}
        </div>
        {togglePhotos === 0 ? <GalleryPhotos /> : <GalleryVideos />}
      </div>
    </div>
  )
}

export default Gallery