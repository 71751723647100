import React from 'react'


let colors = ['#fa9db7', '#b6dd6b', '#d8a7e2', '#95adfa']

const mission = [
  { missionStep: 'Take Vedic sciences to the world.' },
  { missionStep: 'Provide kid’s development courses worldwide to sharpen kid’s brains.' },
  { missionStep: 'Nourish young minds and foster holistic growth through innovative learning experiences.' },
  { missionStep: 'To bridge ancient wisdom with modern practices, providing a holistic approach to education.' },
]


function Mission() {
  return (
    <div className=''>
      <div className='px-5 pt-10'>
        <img src="assets/About/Mission.jpg" className='mt-16' />
        <div className='container relative px-5 pt-10'>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-4">
            {
              mission.map((i, index) => {
                return (
                  <>
                    <div className="h-full dark:bg-gray-800 bg-[colors] flex-col transform translate-y-4 hover:translate-y-0 duration-500 ease-in-out rounded-2xl shadow-1 shadow-xl p-5 pb-7" style={{ backgroundColor: colors[index] }}>
                      <div className='p-2 pb-0 text-center'>
                        <p className="text-base leading-relaxed text-white font-medium ">{i.missionStep}</p>
                      </div>
                    </div>
                  </>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>

  )
}

export default Mission