import React from 'react'

function ContactList() {
    return (
        <div className='grid md:grid-cols-3 grid-cols-2 gap-4 text-center'>
            <div className="text-center px-6">
                <div className="relative text-transparent">
                    <div className="size-20 bg-[#f9fef1] text-[#b6dd6b] rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                    </div>
                </div>
                <div className="content mt-7">
                    <h2 className=" dark:text-white text-2xl font-bold text-center">Phone</h2>
                    <div className="mt-5">
                        <div className="text-[#b6dd6b] font-medium">+919013790037, <br /> +1( 510)8618713</div>
                    </div>
                </div>
            </div>

            <div className="text-center px-6">
                <div className="relative text-transparent">
                    <div className="size-20 bg-[#f4f5fc] text-[#95adfa] rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                    </div>
                </div>
                <div className="content mt-7">
                    <h2 className=" dark:text-white text-2xl font-bold text-center">Location</h2>
                    <div className="mt-5 flex flex-wrap gap-2">
                        <div className="text-[#95adfa] font-medium">India, US, UK, Dubai, Canada, Australia, Germany, New Zealand, Oman</div>
                    </div>
                </div>
            </div>


            <div className="text-center px-6">
                <div className="relative text-transparent">
                    <div className="size-20 bg-[#faf2fd] text-[#d8a7e2] rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                    </div>
                </div>
                <div className="content mt-7">
                    <h2 className=" dark:text-white text-2xl font-bold text-center">Email</h2>
                    <div className="mt-5">
                        <div className="text-[#d8a7e2] font-medium">bacchapantii@gmail.com</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactList