import React from 'react'
import Heading from '../Heading/Heading'

const skills = [
    {
        title: 'Better Skills',
        content: 'Stronger basic concepts & cognitive skills.'
    },
    {
        title: 'Overcome Real Life Challenges',
        content: 'Able to take better decisions in every situations.'
    },
    {
        title: 'Future Success',
        content: 'Crack IIT-JEE, NEET, Civil Services like Competitive Exams'
    }
]

let colors = ['rgb(250,157,183)', 'rgb(69,179,223)', 'rgb(253,197,99)']

function ChildSkills() {
    return (<>
        {/* <Heading /> */}
        <div className='mt-28'>
            <div className='bg-no-repeat bg-cover w-full h-full pt-32' style={{ backgroundImage: `url("assets/ColumnImg/cloudBlue.png")` }}></div>
            <div className='bg-[#88d4f2]'>
                <div className='md:container lg:px-20 px-5'>
                    <img src="assets/ColumnImg/cloud_5.png" className="-mb-7 -ml-16 md:block hidden" />
                    <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
                        {
                            skills.map((i, index) => {
                                return (
                                    <>
                                        <div className='text-center bg-white pt-8 pb-4 rounded-3xl'>
                                            <h3 className="lg:text-2xl text-xl font-black dark:text-white mb-2" style={{ color: colors[index] }}>{i.title}</h3>
                                            <p className="lg:text-base text-sm leading-relaxed text-[#4f5050] font-medium">{i.content}</p>
                                        </div>
                                    </>
                                )

                            })
                        }
                    </div>
                </div>
            </div>
            <img src="assets/ColumnImg/cloud_5.png" className='float-right -mt-7 mr-5 md:block hidden' />
            <div className='bg-no-repeat bg-cover w-full h-full pb-64' style={{ backgroundImage: `url("assets/ColumnImg/cloudsBlue.png")` }}></div>
        </div>
    </>
    )
}

export default ChildSkills