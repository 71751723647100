import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Heading from '../Heading/Heading';
import ModalForm from './ModalForm'; // Import the Modal component

const ContactForm = () => {
    const [parentName, setParentName] = useState('');
    const [email, setEmail] = useState('');
    const [kidsAge, setKidsAge] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false); // State to control modal display
    const [phoneError, setPhoneError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [parentsModalName, setParentsModalName] = useState('');

    // Regular expression for email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const handleSubmit = (e) => {
        e.preventDefault();
        setParentsModalName(`${parentName}`);
        // Validate phone number
        if (!validatePhoneNumber(phoneNumber)) {
            setPhoneError('Please enter a valid phone number (with country code)');
            return;
        } else {
            setPhoneError('');
        }

        // Validate email
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address');
            return;
        } else {
            setEmailError('');
        }

        // Your EmailJS service ID, template ID, and Public Key
        const serviceId = process.env.REACT_APP_SERVICE_ID;
        const templateId = process.env.REACT_APP_TEMPLATE_ID;
        const publicKey = process.env.REACT_APP_PUBLIC_KEY;

        // Create a new object that contains dynamic template params
        const templateParams = {
            from_name: parentName,
            from_email: email,
            from_phoneNumber: phoneNumber,
            to_name: 'Bacchapanti',
            message: message,
            kidsAge: kidsAge,
            city: city,
            country: country
        };

        // Send the email using EmailJS
        emailjs.send(serviceId, templateId, templateParams, publicKey)
            .then((response) => {
                console.log('Email sent successfully!', response);
                setShowModal(true); // Show modal on successful submission
                setParentName('');
                setEmail('');
                setPhoneNumber('');
                setMessage('');
                setCity('');
                setCountry('');
                setKidsAge('');
            })
            .catch((error) => {
                console.error('Error sending email:', error);
            });
    };

    const closeModal = () => {
        setShowModal(false); // Function to close the modal
    };

    // Function to validate phone number (exactly 10 digits)
    const validatePhoneNumber = (phone) => {
        // Regular expression to match phone number with optional country code
        const phoneRegex = /^\+?[0-9]{1,3}?[-. ]?\(?[0-9]{3}\)?[-. ]?[0-9]{3}[-. ]?[0-9]{4}$/;
        return phoneRegex.test(phone);
    };

    // Function to validate email address
    const validateEmail = (email) => {
        return emailRegex.test(email);
    };

    return (
        <div className='grid md:grid-cols-2 pt-28'>
            <div className='md:mr-[-50px] md:pt-28 md:pb-28 md:block hidden'>
                <div className="relative inline-block mx-auto h-[700px] w-full rounded-lg overflow-hidden">
                    <img src="assets/Img/contactUs.png" className="" alt="" />
                </div>
            </div>
            <div className='bg-[#faf2fd] rounded-3xl p-8 md:pl-20'>
                <Heading HeadingTwo="Get in Touch" />
                <p className='text-slate-400'>Fill out this form and our specialists will contact you shortly for detailed consultation.</p>
                <div className="w-full pt-8 max-w-xl">
                    <form className="emailForm flex flex-col gap-8" onSubmit={handleSubmit}>
                        <input
                            className={`rounded-3xl border border-gray-200 w-full py-5 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${phoneError && 'border-red-500'}`}
                            placeholder="Your Full Name"
                            name='Name'
                            type="text"
                            value={parentName}
                            onChange={(e) => setParentName(e.target.value)}
                            required
                        />
                        <div className='flex gap-3'>
                            <input
                                className={`rounded-3xl border border-gray-200 w-full py-5 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${phoneError && 'border-red-500'}`}
                                placeholder="Kid's Age"
                                name='kidsAge'
                                type="number"
                                value={kidsAge}
                                onChange={(e) => setKidsAge(e.target.value)}
                                required
                            />
                            <input
                                className={`rounded-3xl border border-gray-200 w-full py-5 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${phoneError && 'border-red-500'}`}
                                placeholder="Your Phone Number"
                                name='phoneNumber'
                                type="tel" // Changed to tel type for better mobile support
                                value={phoneNumber}
                                onChange={(e) => {
                                    setPhoneNumber(e.target.value);
                                    setPhoneError(''); // Clear phone number error on change
                                }}
                                required
                            />
                        </div>
                        {phoneError && <p className="text-red-500 text-sm">{phoneError}</p>}
                        <div className='flex gap-3'>
                            <input
                                className="rounded-3xl border border-gray-200 w-full py-5 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Country"
                                name='country'
                                type="text"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                required
                            />
                            <input
                                className="rounded-3xl border border-gray-200 w-full py-5 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="City"
                                name='city'
                                type="text"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                            />
                        </div>
                        <input
                            className={`rounded-3xl border border-gray-200 w-full py-5 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${emailError && 'border-red-500'}`}
                            placeholder="Your Email...."
                            name='email'
                            type='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
                        <textarea
                            rows="4"
                            className="rounded-3xl border border-gray-200 w-full py-5 px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Leave a Comment..."
                            name='message'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        ></textarea>
                        <button
                            type="submit"
                            className="relative inline-block w-full text-center rounded-full bg-[#ffc85b]  hover:bg-[#45b3df] text-white p-2 transition duration-500"
                        >
                            <div className='border-2 border-dashed border-white rounded-full p-3 text-base font-bold'>
                                Submit
                            </div>
                        </button>
                        {showModal && <ModalForm closeModal={closeModal} parentsModalName={parentsModalName} />}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
