import React from 'react'
import PageTitle from '../Header/PageTitle'

const teamMember = [
  {
    memberImage: 'assets/TeamImg/Lalit K Garg.jpg',
    memberName: 'Lalit K Garg',
    memberDesignation: 'Founder & CEO ',
    memberDescription: 'Lalit K Garg, the visionary Founder & CEO of Bacchapanti, brings unparalleled dedication and innovation to the realm of education. With a deep-rooted commitment to empowering learners and transforming education, Lalit’s leadership steers Bacchapanti towards excellence and impact. Join us in our journey to redefine education under the guidance of Lalit K Garg, driving positive change and creating opportunities for growth and success.'
  },
  {
    memberImage: 'assets/TeamImg/Sangeeta garg.jpg',
    memberName: 'Sangeeta Garg',
    memberDesignation: 'Head Sales & Marketing',
    memberDescription: 'Sangeeta Garg, as the Head of Sales & Marketing at Bacchapanti, leads with strategic prowess and a customer-centric approach. With a strong background in driving revenue growth and fostering brand recognition, Sangeeta spearheads our efforts to reach new heights in the education sector. Join us in revolutionizing learning experiences under Sangeeta Garg’s leadership, where innovation meets impact and success.'
  },
  {
    memberImage: 'assets/TeamImg/Prasang Gupta.jpg',
    memberName: 'Prasang Gupta (IIT kanpur)',
    memberDesignation: 'Head Operation Management',
    memberDescription: 'Prasang Gupta, the dynamic Head of Operations Management at Bacchapanti, brings a wealth of expertise in optimizing processes and driving operational excellence. With a strategic mindset and a focus on efficiency, Prasang ensures smooth functioning and seamless coordination across all aspects of our education platform. Join us in our mission to deliver quality education with Prasang Gupta at the helm of operations, shaping the future of learning for generations to come.'
  },
  {
    memberImage: 'assets/TeamImg/Ruchi Gupta.jpg',
    memberName: 'Ruchi Gupta',
    memberDesignation: 'Global Head Teaching',
    memberDescription: 'Ruchi Gupta, our esteemed Global Head of Teaching at Bacchapanti, embodies a passion for educational excellence and a commitment to nurturing young minds worldwide. With a deep understanding of pedagogy and innovative teaching methodologies, Ruchi leads our team of educators to inspire curiosity and foster a love for learning. Join us in our mission to empower the next generation with Ruchi Gupta’s visionary leadership, creating transformative educational experiences on a global scale.'
  },
  {
    memberImage: 'assets/TeamImg/Leena Mangla.jpg',
    memberName: 'Leena Mangla',
    memberDesignation: 'Head India Operation',
    memberDescription: 'Leena Mangla, an experienced leader with a passion for education, drives the helm of Bacchapanti’s India operations. With a proven track record of strategic planning and execution, Leena brings a wealth of expertise in fostering educational excellence. Under her guidance, Bacchapanti is poised to revolutionize the education landscape, ensuring holistic growth and development for every child. Join us in our mission to empower young minds and shape the future of education with Leena Mangla at the helm.'
  },
  {
    memberImage: 'assets/TeamImg/Shweta Saraswat.jpg',
    memberName: 'Shweta Saraswat',
    memberDesignation: 'Director USA Operation',
    memberDescription: 'Shweta Saraswat, as the Director Head of Operations at Bacchapanti, brings a strategic vision and operational expertise to streamline processes and enhance efficiency. With a strong focus on delivering exceptional services and ensuring seamless coordination, Shweta drives our operational excellence, enabling Bacchapanti to meet and exceed organizational goals. Join us in our journey to revolutionize education under Shweta Saraswat’s leadership, where innovation and dedication meet to create impactful learning experiences.'
  },
  {
    memberImage: 'assets/TeamImg/Seema Gupta.jpg',
    memberName: 'Seema Gupta',
    memberDesignation: 'Head Customer Relation',
    memberDescription: 'Seema Gupta, our dedicated Head of Customer Relations at Bacchapanti, ensures exceptional experiences for our students and partners. With a focus on building strong relationships and delivering personalized support, Seema leads our customer relations team in providing timely assistance and solutions. Join us in our commitment to excellence and customer satisfaction under Seema Gupta’s leadership, where every interaction is a testament to our dedication to fostering positive educational journeys.'
  },
  {
    memberImage: 'assets/TeamImg/Indu Aggarwal.jpg',
    memberName: 'Indu Aggarwal',
    memberDesignation: 'Community Owner Parenting',
    memberDescription: 'Indu Aggarwal, our esteemed Community Owner Parenting at Bacchapanti, fosters a supportive and engaging environment for parents. With a deep understanding of parenting challenges and effective communication skills, Indu facilitates meaningful connections and valuable resources within our parenting community. Join us in our mission to empower parents and nurture healthy family dynamics under Indu Aggarwal’s guidance, where every parent’s voice is heard and valued.'
  },
  {
    memberImage: 'assets/TeamImg/Rajendar Prasad.jpg',
    memberName: 'Rajendar Kala',
    memberDesignation: 'Head Course Development',
    memberDescription: 'Rajendra Kala, our dynamic Head of Course Development at Bacchapanti, leads the creation of innovative and engaging learning experiences. With a focus on curriculum design and instructional strategies, Rajendra ensures that our courses meet the highest educational standards. Join us in our mission to inspire and educate learners with Rajendra Kala’s visionary leadership, where every course is designed to empower minds and foster success.'
  },
  {
    memberImage: 'assets/TeamImg/Dr. Shweta Goyal.jpg',
    memberName: 'Dr. Shweta Goyal',
    memberDesignation: 'Pediatrician',
    memberDescription: 'At Bacchapanti, we are proud to have Dr. Shweta Goyal, a dedicated pediatrician, as part of our team. Dr. Goyal’s expertise and commitment to children’s well-being are unparalleled, and she plays a crucial role in providing specialized care and support for our young learners. Join us in our mission to nurture and empower children, supported by expert pediatric care from Dr. Shweta Goyal.'
  },
  {
    memberImage: 'assets/TeamImg/Garima Gupta.jpg',
    memberName: 'Garima Gupta',
    memberDesignation: 'Neuro-Phycologist',
    memberDescription: 'Garima Gupta, our esteemed Neuropsychologist at Bacchapanti, brings expertise in understanding the intricate workings of the brain and its impact on learning and behavior. With a focus on cognitive development and mental well-being, Garima designs personalized interventions to support students academic success and overall growth. Join us in providing holistic education experiences under Garima Gupta’s guidance, where every child’s potential is nurtured and celebrated.'
  },
  {
    memberImage: 'assets/TeamImg/Nidhi Chauhan.jpg',
    memberName: 'Nidhi Chauhan',
    memberDesignation: 'Head Content Developer',
    memberDescription: 'Nidhi Chauhan, our Head Content Developer at Bacchapanti, leads the creation of engaging and impactful educational content. With a passion for innovation and a deep understanding of instructional design, Nidhi ensures that our courses are relevant, interactive, and aligned with educational standards. Join us in delivering quality learning experiences under Nidhi Chauhan’s leadership, where every piece of content is designed to inspire and empower learners.'
  },
  {
    memberImage: 'assets/TeamImg/Diya Mittal.jpg',
    memberName: 'Diya Mittal',
    memberDesignation: 'Head Designing Team',
    memberDescription: 'Diya Mittal, our Head of the Designing Team at Bacchapanti, leads with creativity and vision to craft visually appealing and engaging educational materials. With expertise in graphic design and multimedia production, Diya ensures that our content is not only informative but also captivating for learners. Join us in delivering immersive learning experiences under Diya Mittal’s leadership, where design meets education to inspire and empower.'
  },
  {
    memberImage: 'assets/TeamImg/Priyanka Goyal.jpg',
    memberName: 'Priyanka Goyal',
    memberDesignation: 'Right Brain Executor-Balpan',
    memberDescription: 'Priyanka Goyal, our dedicated Right Brain Executor-Balpan at Bacchapanti, brings a unique blend of creativity and strategic thinking to drive innovative initiatives. With a focus on unleashing creative potential and fostering out-of-the-box solutions, Priyanka leads our efforts in developing engaging educational content and experiences. Join us in our mission to inspire creativity and ignite imaginations under Priyanka Goyal’s guidance, where every idea finds expression and every learner discovers their true potential.'
  }
]


function Team() {
  return (
    <div>
      <PageTitle title="Team" activeTitle="Team" />
      <div className='pt-24 container'>
        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 pt-6 gap-6">
          {
            teamMember.map((member) => {
              return (
                <div className="group cursor-pointer">
                  <div className="relative overflow-hidden rounded-md duration-500">
                    <div className="relative rounded-t-full overflow-hidden">
                      <img src={member.memberImage} className="group-hover:scale-110 duration-500" alt="" />
                      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black rounded-t-full opacity-0 group-hover:opacity-100 duration-500"></div>
                    </div>
                    <div className="absolute md:-bottom-96 group-hover:bottom-3 start-3 end-3 duration-500">
                      <div className="text-center items-center justify-center mb-2 py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-xs bg-[#95adfa] text-white rounded-md">{member.memberName}<br />{member.memberDesignation}</div>
                      <div className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-xs text-center bg-[#fa9db7] text-white w-full rounded-md">{member.memberDescription}</div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Team