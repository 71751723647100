import React, { useEffect, useState } from 'react'
import Heading from '../Heading/Heading'
import KidsModal from '../ModalVideo/KidsModal'
import { useFirebase } from '../Helper/Firebase';

const GalleryVideo = ["All Videos", "Live Session", "Blind Fold", "Parents Review", "Kid Reviews", "News & Interview", "Events & Workshop"]
function GalleryVideos() {

  const firebase = useFirebase();

  const [toggleVideo, setToggleVideo] = useState(GalleryVideo[0]);
  const [GalleryData,setGalleryData] = useState([]);
  const [CategoryName,setCategoryName] = useState(GalleryVideo[0]);


  const toggleTab = (index) => {
    setToggleVideo(index);
    setCategoryName(index);
  };

  useEffect(()=>{
    firebase.fileData("Video",CategoryName).then((data) => setGalleryData(data.docs));
  },[toggleVideo])


  return (
    <div className='mt-8'>
      <div className='flex flex-wrap justify-center flex-grow gap-7'>
        {GalleryVideo.map((items, index) => (<>
          <div key={index}>
            <button
              className={toggleVideo === items ? "relative block bg-none text-[#45b3df] text-2xl font-extrabold after:absolute after:left-0 after:bottom-[-12px] after:bg-[#45b3df] after:h-1 after:w-full" : "relative block font-bold bg-none text-[#fa9db7] text-2xl"}
              onClick={() => toggleTab(items)}
            >
              {items}
            </button>
          </div>
        </>))}
      </div>
      <div>
        
        <div className='grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-5 pt-12'>
          {
            GalleryData.map((videosItem, imagesIndex) => {
              return (
                <div className='relative text-center' key={videosItem.data().id}>
                    <img src={videosItem.data().thumbnail} className='rounded-lg h-40 w-full lg:h-60 md:h-60' />
                   <KidsModal VideoModalVideos={videosItem.data().fileUrl} />
                </div>
              )
            })
          }
        </div>
            
      </div>
    </div>
  )
}

export default GalleryVideos