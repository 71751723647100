import React, { useState } from 'react'
import Heading from '../Heading/Heading'
import ParentsModal from '../ModalVideo/ParentsModal'
import Button from '../Heading/Button'
import SideIcon from '../SideIcon/SideIcon'

let colors = ['rgb(250,157,183)', 'rgb(253,197,99)', 'rgb(69,179,223)', '#95adfa', '#b6dd6b', '#d8a7e2']


const parentSays = [
    {
        says: 'We are seeing many improvement in our child Prisha after joining Baccha Panti classNamees. She is enjoying the exercises , activities and Special blind fold very much which are very useful to her . She has improved her concentration level and learning power , she is able to sove maths problems wery easily and is now able to see with blind fold .There is a huge process in our daughter. Thank You so much Baccha Panti team 🙏🏻',
        parentName: 'SHWETA VERMA',
        parentsVideo: 'https://www.youtube.com/embed/d5dedlfC_eo?si=N6ijfqStOdZg3FPX"'
    },
    {
        says: 'Every parent should enroll their kids at Bacchapanti, if they are facing issues like concentration, lack of focus and lack of learning abilities.',
        parentName: 'SHALU SINGLA',
        parentsVideo: 'https://www.youtube.com/embed/9UJVQLPbHxY?si=eDV2gt1kY5ylpFxC'
    },
    {
        says: 'Bacchapanti is an mazing platform! I am very grateful to all the Bacchapanti trainers as I see so many improvements in my daughter like she can sit at one place and read a book for long time, she can do mathematic calculations very fastly. Her focus, concentration, memory and cognitive abilities have improved a lot.Because of Mishti everyone in the family started doing meditation even my 4 year old younger daughter as well:). The way bacchapanti makes their online program so interesting, interactive and kids friendly is highly commendable',
        parentName: 'Sneh',
        parentsVideo: 'https://www.youtube.com/embed/AYnkGwRwq5M?si=eX8P7h6tao2cPqqO'

    },
    {
        says: 'Bachchapanti is Fabulous organization. They teach a very different kind of program and unique activities. My daughter Disha Jain is one of the student of Bachchapanti team. The management and teacher are very appreciable because they focus on each and every students growth and also Mr. Lalit Garg Founder of Bachchapanti always take feedback from parents for their new challenges.',
        parentName: 'Dr. Darshana Rathor (INDIA)',
        parentsVideo: 'https://www.youtube.com/embed/3T7lZwUz0s8?si=SdPj8ITT-MYiMnIv'
    },

]

function ParentsReviews() {

    return (
        <>
            <div className='px-5 md:mt-28 mt-12'>
                <SideIcon floatingLeftIcon="familyGirl.png" floatingRightIcon="familyBoy.png" />
                <div className='text-center mb-10'>
                    <Heading HeadingOne="What" HeadingTwo="Parent's Says" />
                </div>
                <div className='grid lg:grid-cols-4 md:grid-cols-2 gap-2'>
                    {
                        parentSays.map((parents, index) => {
                            return (
                                <div className='mb-14'>
                                    <div className="rounded-lg relative h-full p-8 pb-0" style={{ backgroundColor: colors[index] }}>
                                        <p className="leading-relaxed md:text-base text-sm  text-white">{parents.says}</p>
                                    </div>
                                    <div className='absolute pl-5'>
                                        <svg className=" w-8 transition-[inherit] p-0" style={{ fill: colors[index] }} st viewBox="0 0 259.43 367.5">
                                            <path d="M.53,0H255.84c15,14-23.29,233.58.07,367.5C255.91,367.5-13.49,272.51.53,0Z"></path>
                                        </svg>
                                    </div>
                                    <div className="flex items-center gap-2 justify-end mt-3">
                                        <ParentsModal VideoModalVideos={parents.parentsVideo} />
                                        <h2 className="md:text-lg text-sm font-bold">{parents.parentName}</h2>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>)
}

export default ParentsReviews