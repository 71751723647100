import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const CountrySlides = [
    { countryImage: 'assets/Flags/india.png', countryName: 'India' },
    { countryImage: 'assets/Flags/US.png', countryName: 'US' },
    { countryImage: 'assets/Flags/UK.png', countryName: 'UK' },
    { countryImage: 'assets/Flags/Dubai.png', countryName: 'Dubai' },
    { countryImage: 'assets/Flags/canada.png', countryName: 'Canada' },
    { countryImage: 'assets/Flags/australia.png', countryName: 'Australia' },
    { countryImage: 'assets/Flags/germany.png', countryName: 'Germany' },
    { countryImage: 'assets/Flags/new-zealand.png', countryName: 'New Zealand' },
    { countryImage: 'assets/Flags/oman.png', countryName: 'Oman' }
]


function CountrySlids() {
    const countrySlide = {
        loop: true,
        animateOut: 'fadeOut',
        margin: 10,
        navSpeed: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 1000,
        autoplayHoverPause: true,
        smartSpeed: 1000,
        autoplayHoverPause: true,
        navText: [
            "<i className='bx bx-chevron-left'></i>",
            "<i className='bx bx-chevron-right'></i>",
        ],
        responsive: {
            0: {
                items: 3,
            },
            576: {
                items: 3,
            },
            768: {
                items: 4,
            },
            1200: {
                items: 5,
            },
            margin: 5
        }
    }
    return (
        <div className='mt-28'>
            <div className='bg-no-repeat bg-cover pt-24' style={{ backgroundImage: `url("assets/ColumnImg/cloudYellow.png")` }}></div>
            <div className='bg-[#ffdd98] text-center md:p-16'>
                <OwlCarousel className="owl-theme owl-carousel" {...countrySlide}>
                    {
                        CountrySlides.map((i) => {
                            return (
                                <div className='flex flex-col items-center'>
                                    <img src={i.countryImage} className="inline-block mr-2" style={{ width: '80px' }} />
                                    <span className="text-white md:text-3xl text-sm">{i.countryName}</span>
                                </div>
                            )
                        })
                    }
                </OwlCarousel>
            </div>
            <img src="assets/ColumnImg/cloud_5.png" className='float-right md:block hidden' />
            <div className='bg-no-repeat bg-cover pb-32' style={{ backgroundImage: `url("assets/ColumnImg/cloudsYellow.png")` }}></div>
        </div>
    )
}

export default CountrySlids