import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

function Footer() {

    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            // Adjust the threshold (e.g., 200) based on when you want the link to appear
            if (currentScrollY > 200) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        // Clean up the scroll event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (<>
        <footer className="footer  relative text-gray-200 dark:text-gray-200 mt-28">
            <div className='p-10' style={{ backgroundImage: `url("assets/ColumnImg/footer_bg.png")` }}></div>
            <div className='bg-[#2d4073]'>
                <div className="container relative py-[60px] px-0">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                        <div className="lg:col-span-3 md:col-span-12">
                            <h3 className='text-3xl font-bold text-[#ffff]'>Get In Touch</h3>
                            <div className="flex items-center gap-2 mt-5">
                                <div className="relative items-center text-transparent">
                                    <div className="size-5 text-[#ffffff80] hover:text-orange-500 dark:hover:text-orange-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="text-[#ffffff80] font-medium">+919013790037, <br /> +1( 510)8618713</div>
                                </div>
                            </div>
                            <div className="flex items-center gap-2 mt-5">
                                <div className="relative items-center text-transparent">
                                    <div className="size-5 text-[#ffffff80] rounded-xl hover:text-orange-500 dark:hover:text-orange-500 text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="text-[#ffffff80] font-medium">India, USA</div>
                                </div>
                            </div>
                            <div className="flex items-center gap-2 mt-5 ">
                                <div className="relative items-center text-transparent">
                                    <div className="size-5 text-[#ffffff80] rounded-xl hover:text-orange-500 dark:hover:text-orange-500 text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="text-[#ffffff80] font-medium">bacchapantii@gmail.com</div>
                                </div>
                            </div>
                            <ul className="list-none mt-6">
                                <li className="inline mr-1"><div className="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-linkedin h-4 w-4 align-middle" title="Linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg></div></li>
                                <li className="inline mr-1"><div className="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-facebook h-4 w-4 align-middle" title="facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg></div></li>
                                <li className="inline mr-1"><div className="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-instagram h-4 w-4 align-middle" title="instagram"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg></div></li>
                                <li className="inline mr-1"><div className="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-twitter h-4 w-4 align-middle" title="twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg></div></li>
                                <li className="inline mr-1"><div className="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-mail h-4 w-4 align-middle" title="email"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg></div></li>
                            </ul>
                        </div>

                        <div className="lg:col-span-3 md:col-span-12">
                            <h3 className='text-3xl font-bold text-[#ffff]'>Quick Links</h3>
                            <div className="grid md:grid-cols-12 grid-cols-1">
                                <div className="md:col-span-4">
                                    <ul className="list-none footer-list mt-6">
                                        <li className="mt-[10px]"><Link to="/about" className="flex text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><svg className="h-6 w-6 text-gray-300" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="9 6 15 12 9 18" /></svg> About </Link></li>
                                        <li className="mt-[10px]"><Link to="/gallery" className="flex text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><svg className="h-6 w-6 text-gray-300" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="9 6 15 12 9 18" /></svg> Gallery </Link></li>
                                        <li className="mt-[10px]"><Link to="/team" className="flex text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><svg className="h-6 w-6 text-gray-300" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="9 6 15 12 9 18" /></svg>  Team </Link></li>
                                        <li className="mt-[10px]"><Link to="/blog" className="flex text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><svg className="h-6 w-6 text-gray-300" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="9 6 15 12 9 18" /></svg> Blog </Link></li>
                                        <li className="mt-[10px]"><Link to="/contact" className="flex text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><svg className="h-6 w-6 text-gray-300" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="9 6 15 12 9 18" /></svg> Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-3 md:col-span-12">
                            <h3 className='text-3xl font-bold text-[#ffff]'>Photo Gallery</h3>
                            <div className='grid grid-cols-3 gap-2'>
                                <div className="mt-5">
                                    <img className="border rounded-lg bg-[#FFF5F3] p-1" src="assets/Gallery/photo1.png" alt="" />
                                </div>
                                <div className="mt-5">
                                    <img className="border rounded-lg bg-[#FFF5F3] p-1" src="assets/Gallery/meditation9.png" alt="" />
                                </div>
                                <div className="mt-5">
                                    <img className="border rounded-lg bg-[#FFF5F3] p-1" src="assets/Gallery/Reward8.png" alt="" />
                                </div>
                                <div className="mt-5">
                                    <img className="border rounded-lg bg-[#FFF5F3] p-1" src="assets/Gallery/blindFold2.png" alt="" />
                                </div>
                                <div className="mt-5">
                                    <img className="border rounded-lg bg-[#FFF5F3] p-1" src="assets/Gallery/photo7.png" alt="" />
                                </div>
                                <div className="mt-5">
                                    <img className="border rounded-lg bg-[#FFF5F3] p-1" src="assets/Gallery/meditation4.png" alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-3 md:col-span-4">
                            <h3 className='text-3xl font-bold text-[#ffff]'>NewsLetter</h3>
                            <p className="mt-6">Sign up and receive the latest tips via email.</p>
                            <form>
                                <div className="grid grid-cols-1">
                                    <div className="my-3">
                                        <label className="form-label">Write your email <span className="text-red-600">*</span></label>
                                        <div className="form-icon relative mt-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-mail size-4 absolute top-3 start-4"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                            <input type="email" className="ps-12 rounded w-full py-2 px-3 h-10 bg-gray-800 border-0 text-gray-100 focus:shadow-none focus:ring-0 placeholder:text-gray-200 outline-none" placeholder="Email" name="email" required="" />
                                        </div>
                                    </div>

                                    <button type="submit" id="submitsubscribe" name="send" className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md">Subscribe</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* <div className="py-[30px] px-0 border-t border-white-800">
                    <div className="container relative text-center">
                        <div className="grid md:grid-cols-2 items-center">
                            <div className="md:text-start text-center">
                                <p className="mb-0">© <script>document.write(new Date().getFullYear())</script>2024 Bacchapanti. Design with <i className="mdi mdi-heart text-red-600"></i> by <span className="text-reset">Bhavya Bansal</span>.</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </footer>
        <Link
            to="/contact"
            className={`fixed z-20 bottom-5 right-5 inline-block w-20 h-20 text-center rounded-full bg-[#45b3df] hover:bg-[#ffc85b] text-white p-2 transition duration-500 ${isVisible ? 'visible' : 'invisible'}`}
        >
            <div className='border-2 border-dashed border-white rounded-full w-16 h-16 p-3 text-xs font-bold'>
                Free Demo
            </div>
        </Link>
    </>)
}

export default Footer