import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Button from '../Heading/Button'

function EnrollNow() {
    return (
        <div className='mt-28'>
            <div className='bg-no-repeat bg-cover pt-24' style={{ backgroundImage: `url("assets/ColumnImg/columnPink.png")` }}></div>
            <div className='bg-[#ffb9cc] text-white text-center leading-6 flex flex-col gap-4'>
                <h1 className="md:text-3xl text-xl leading-loose font-extrabold dark:text-white">Enroll Your Child Now! Call Us</h1>
                <h1 className="md:text-4xl text-2xl font-extrabold leading-relaxed dark:text-white">+91-9013790037 or <br />+1(510) 861-8713</h1>
                <div className=' text-center'>
                    <Link to="/contact" className='relative inline-block text-center rounded-full bg-white hover:bg-[#ffc85b] text-[#45b3df] hover:text-white p-2 transition duration-500'>
                        <div className='border-2 border-dashed border-[#45b3df] rounded-full p-3 px-12 text-base font-bold hover:border-white'>
                            Enroll Online
                        </div>
                    </Link>
                </div>
            </div>
            <img src="assets/ColumnImg/cloud_5.png" className='float-right md:block hidden' />
            <div className='bg-no-repeat bg-cover md:pb-44 pb-28' style={{ backgroundImage: `url("assets/ColumnImg/columnPinks.png")` }}></div>
        </div>
    )
}

export default EnrollNow