import React, { useRef, useState } from 'react';
import Heading from '../Helper/Heading';
import SingleSelectDropdown from '../Helper/SingleSelectDropdown';
import { useFirebase } from './Firebase';
import Loader from './Loader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputField from '../Helper/InputField';
import PageTitle from '../Header/PageTitle';
import AdminSelectedImage from './AdminSelectedImage';
import AdminSelectedVideo from './AdminSelectedVideo';
import { useHistory } from 'react-router-dom'; // Use useHistory instead of useNavigate
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

const ImageCategory = [
    { value: 'Meditation', label: 'Meditation', isFixed: true },
    { value: 'Brain Gym Exercise', label: 'Brain Gym Exercise' },
    { value: 'BlindFold', label: 'BlindFold', isFixed: true },
    { value: 'Rewards', label: 'Rewards' },
    { value: 'News & Interview', label: 'News & Interview' },
    { value: 'Events & Workshop', label: 'Events & Workshop' }
];
const TypeCategory = [
    { value: 'Image', label: 'Images', isFixed: true },
    { value: 'Video', label: 'Videos' },
];

const VideoCategory = [
    { value: 'Live Session', label: 'Live Session', isFixed: true },
    { value: 'Blind Fold', label: 'Blind Fold' },
    { value: 'Parents Review', label: 'Parents Review', isFixed: true },
    { value: 'Kid Reviews', label: 'Kid Reviews' },
    { value: 'News & Interview', label: 'News & Interview' },
    { value: 'Events & Workshop', label: 'Events & Workshop' }
];

toast.configure();

function Admin() {
    const history = useHistory(); // Initialize useHistory
    const [images, setImages] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [Type, setType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [Url, setUrl] = useState('');
    const [Thumbnail, setThumbnail] = useState('');
    const [errors, setErrors] = useState({});
    const [isDataInserted, setIsDataInserted] = useState(false); // New state variable

    const firebase = useFirebase();
    const inputFile = useRef(null);

    const handleFileUpload = async e => {
        const { files } = e.target;
        if (files && files.length) {
            const updatedImages = [...selectedImages];
            for (let i = 0; i < files.length; i++) {
                updatedImages.push({ file: files[i], name: files[i].name });
            }
            setSelectedImages(updatedImages);
            setIsLoading(true);
            // If you want to upload images immediately after selection, uncomment the line below
            const images = await firebase.uploadMultipleImages(e.target.files);
            console.log(images);
            Type.value === 'Image' ? setImages(images) : setThumbnail(images[0]);
            setIsLoading(false);
        }
    };

    const removeImage = async (index) => {
        const updatedImages = [...selectedImages];
        console.log(updatedImages);
        const filename = updatedImages[index].name;
        await firebase.deleteImage('/uploads/' + filename);
        images.splice(index, 1);
        //setImages(data)
        updatedImages.splice(index, 1);
        setSelectedImages(updatedImages);
    };

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const logOut = async (e) => {
        e.preventDefault();
        try {
            await firebase.logOut();
            history.push('/'); // Redirect to the homepage after logout
        } catch (error) {
            console.error('Logout error:', error);
            toast.error('Failed to log out. Please try again.');
        }
    };

    const uploadImagesToBackend = (images) => {
        const formData = new FormData();
        images.forEach((image, index) => {
            formData.append(`image${index}`, image.file);
        });
        const imageNames = selectedImages.map(image => image.name);
        console.log(imageNames);
        // // Send image names to backend
        // fetch('/api/uploadImages', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({ imageNames })
        // })
        //     .then(response => {
        //         // Handle response if needed
        //     })
        //     .catch(error => {
        //         console.error('Error uploading images to backend:', error);
        //     });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!Type.value) newErrors.Type = 'Please select a type';
        if (!selectedCategory.value) newErrors.selectedCategory = 'Please select a category';
        if (Type.value === 'Image' && images.length === 0 && selectedImages.length === 0) newErrors.images = 'Please upload at least one image';
        if (Type.value === 'Video' && !Url) newErrors.Url = 'Please provide a YouTube URL';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        console.log('in process');
        await firebase.addProduct({
            type: Type.value,
            categoryId: selectedCategory.value,
            fileUrl: images === '' ? Url : images,
            thumbnail: Type.value === 'Video' ? Thumbnail : ''
        });
        console.log('Inserted');
        resetData();
        toast.success('Data Inserted', { autoClose: 3000 });
        setIsDataInserted(true);
    };

    const resetData = () => {
        setImages('');
        setSelectedImages([]);
        setType('');
        setSelectedCategory('');
        setIsLoading(false);
    };

    return (
        <>
            <PageTitle title='Admin' activeTitle='Admin' />
            <div className='container relative lg:mt-20 md:mt-10 mt-5'>
                {isLoading ? (
                    <Loader loader={isLoading} />
                ) : (
                    <div>
                        <div className=''>
                            <button onClick={logOut} className='float-right bg-blue-500 text-white px-3 py-2 rounded-md'><FontAwesomeIcon icon={faRightFromBracket} /></button>
                        </div>
                        <div className='flex flex-wrap -mx-3 mb-6'>
                            <div className='w-full md:w-1/3 px-3 mb-6 md:mb-0'>
                                <SingleSelectDropdown selectLabel='Select Type' selectedDefaultValue={TypeCategory} selectedOptions={TypeCategory} selectedValue={Type} handleSelectedChange={(selectedTypeOption) => setType(selectedTypeOption)} />
                                {errors.Type && <p className='text-red-500 text-sm'>{errors.Type}</p>}
                            </div>
                            <div className='w-full md:w-1/3 px-3 mb-6 md:mb-0'>
                                {Type.value === 'Image' ?
                                    <SingleSelectDropdown selectLabel='Select Category' selectedDefaultValue={ImageCategory} selectedOptions={ImageCategory} selectedValue={selectedCategory} handleSelectedChange={(selectedCategoryOption) => setSelectedCategory(selectedCategoryOption)} /> :
                                    <SingleSelectDropdown selectLabel='Select Category' selectedDefaultValue={VideoCategory} selectedOptions={VideoCategory} selectedValue={selectedCategory} handleSelectedChange={(selectedCategoryOption) => setSelectedCategory(selectedCategoryOption)} />
                                }
                                {errors.selectedCategory && <p className='text-red-500 text-sm'>{errors.selectedCategory}</p>}

                            </div>
                        </div>
                        {/* <div className='flex flex-wrap -mx-3 mb-6'>
                            <div className='w-full md:w-1/3 px-3 mb-6 md:mb-0'>
                                {Type.value === 'Image' ?
                                    <SingleSelectDropdown selectLabel='Select Category' selectedDefaultValue={ImageCategory} selectedOptions={ImageCategory} selectedValue={selectedCategory} handleSelectedChange={(selectedCategoryOption) => setSelectedCategory(selectedCategoryOption)} /> :
                                    <SingleSelectDropdown selectLabel='Select Category' selectedDefaultValue={VideoCategory} selectedOptions={VideoCategory} selectedValue={selectedCategory} handleSelectedChange={(selectedCategoryOption) => setSelectedCategory(selectedCategoryOption)} />
                                }
                                {errors.selectedCategory && <p className='text-red-500 text-sm'>{errors.selectedCategory}</p>}

                            </div>
                        </div> */}
                        <div className='rounded-md shadow pb-1 bg-white mb-6'>
                            <Heading HeadingFive='Media' />
                            <div className='m-10 rounded-md border-dashed border-2 border-[#d9dee3] dark:border-gray-700 cursor-pointer'>
                                <div className='text-center my-12'>
                                    <div onClick={onButtonClick}>
                                        {/* <img src="./Assets/Admin/mediapicker.svg" className='w-28 h-auto relative inline-block' /> */}
                                        <p className='block text-[#697a8d] font-medium text-xl my-3'>Drag and drop your images here</p>
                                        <p className='block my-3 text-[#a1acb8] opacity-100 font-medium'>Or</p>
                                        <input style={{ display: 'none' }}
                                            ref={inputFile}
                                            onChange={handleFileUpload}
                                            type='file'
                                            multiple // Allow multiple file selection
                                        />
                                        <button onClick={() => uploadImagesToBackend(selectedImages)} className='bg-[#e7e7ff] text-[#696cff] font-medium py-2 px-4 rounded text-base'>
                                            Upload Images
                                        </button>
                                    </div>
                                    <div className='grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-5'>
                                        {selectedImages.map((image, index) => (
                                            <div key={index} className='flex items-center justify-between relative rounded-md shadow dark:shadow-gray-800 border-[#d9dee3] bg-white my-4 mx-5 p-4'>
                                                <div className='grid gap-3'>
                                                    <img src={URL.createObjectURL(image.file)} alt={image.name} className='w-auto h-auto relative inline-block' />
                                                    <p className='block text-[#697a8d] font-medium text-sm my-3'>{image.name}</p>
                                                </div>
                                                <button onClick={() => removeImage(index)}
                                                    className='size-4 inline-flex items-center justify-center absolute right-1 top-1 tracking-wide align-middle duration-500 text-base text-center bg-red-600 rounded-full'>
                                                    <svg xmlns='http://www.w3.org/2000/svg' height='10' width='7.5' viewBox='0 0 384 512'>
                                                        <path fill='#fefbfb' d='M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z' />
                                                    </svg>
                                                </button>
                                            </div>
                                        ))}
                                        {errors.images && <p className='text-red-500 text-sm'>{errors.images}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {Type.value === 'Video' ? (
                            <div className='mb-6 relative'>
                                <InputField label='Youtube URL' inputType='text' inputField={Url} handleInputField={(e) => setUrl(e.target.value)} />
                                {errors.Url && <p className='text-red-500 text-sm'>{errors.Url}</p>}
                            </div>
                        ) : null}
                        <div className='w-full md:w-1/6 px-3 md:mb-0'>
                            <button onClick={handleSubmit} className='bg-blue-500 text-white px-3 py-2 rounded-md'>Add</button>
                        </div>
                    </div>
                )}
            </div>
            <AdminSelectedImage dataToggle={isDataInserted} />
            <AdminSelectedVideo prop={isDataInserted} />
        </>
    );
}

export default Admin;
